<template>
    <div>
        <ChatView></ChatView>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import ChatView from "@/components/ChatView.vue";
export default defineComponent({
  name: 'HomeView',
    components: {ChatView}
});
</script>
