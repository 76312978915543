<template>
      <v-col :cols="12" class="mx-auto v-col-md-6">
          <v-dialog
                  :model-value="!logged"
                  class="v-col-12 v-col-md-6"
                  persistent
          >
              <v-card title="Login">
                  <v-card-actions>
                      <v-text-field
                              @keydown="keydown_pseudo"
                              v-model="pseudo"
                              :error-messages="error_psuedo"
                              placeholder="Enter your pseudo"
                              variant="solo"
                              counter
                              autofocus
                              persistent-counter
                              :maxlength="10"
                              density="compact"
                      ></v-text-field>
                      <v-btn
                              @click="connection(pseudo)"
                              :disabled="pseudo.trim().length < 3"
                              text="login"
                              class="mb-5 ml-2"
                              color="blue"
                              variant="tonal"
                      ></v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <v-list class="px-3">
              <v-list-subheader>Chat</v-list-subheader>
              <div v-for="(message, index) in messages" :key="index">
                  <v-list-item
                          :prepend-avatar="require('../assets/account-circle-outline-icon.png')"
                          :title="message?.user"
                          :subtitle="message?.msg"
                  ></v-list-item>
                  <v-divider inset></v-divider>
              </div>
          </v-list>
          <div id="bottom" class="v-col-12 v-col-md-6 px-3">
              <v-text-field
                      id="input_message"
                      @click:append-inner="sendMessage(input_message)"
                      @keydown="keydown_message"
                      v-model="input_message"
                      :error-messages="error_message"
                      placeholder="Send a message"
                      counter
                      persistent-counter
                      persistent-clear
                      clearable
                      :maxlength="45"
                      density="compact"
                      variant="solo"
                      append-inner-icon="mdi-send"
                      class="pt-15 pb-2"
              ></v-text-field>
          </div>
      </v-col>
</template>

<script>
import io from "socket.io-client";
export default {
    name: "ChatView",
    data: () => ({
        messages: [],
        input_message: "",
        error_psuedo: "",
        error_message: "",
        pseudo: "",
        logged: false
    }),
    methods: {
        connection(pseudo) {
            this.error_psuedo = ""
            pseudo = pseudo.trim()
            this.socket.emit('new user', {text: pseudo}, (data) => {
                if (data) {
                    this.logged=true
                    this.sendMessage(`${this.pseudo} is connected.`)
                } else {
                    this.error_psuedo = "This pseudo is already taken."
                }
            })
        },
        sendMessage(message) {
            this.error_message = false;
            if (!message.trim()) {
                return this.error_message = "You need to write something to send a message.";
            }
            this.socket.emit('send message', {text: message});
            this.input_message = ""
        },
        keydown_pseudo(e) {
            if (e.key === "Enter") {
                this.connection(this.pseudo)
            }
        },
        keydown_message(e) {
            if (e.key === "Enter") {
                this.sendMessage(this.input_message)
            }
        }
    },
    mounted() {
        this.socket = io.connect("chat.nexford.fr");
        this.socket.on('new message', (message) => {
            this.messages.splice(0, 0, message)
        });
        this.socket.on('usernames', (data) => {
            console.log({usernames: data})
        });
    },
    beforeUnmount() {
        this.socket.disconnect();
    },
}
</script>

<style scoped>
#bottom {
    position: fixed;
    bottom: 0;
    background-image: linear-gradient(
            180deg,
            hsla(0,0%,100%,0) 0%,
            #fff 40%);
}
.v-col-6, .v-col-12 {
    padding: 0;
}
</style>